<script lang="ts" setup>
import { EccaColorSet } from "@ecca/ui-components";
import { computed, useSlots } from "vue";
import { useModelValueWrapper } from "@/shared/hooks/useModelValueWrapper";
import UiActionRow from "@/shared/ui/UiActionRow.vue";

const props = withDefaults(
  defineProps<{
    variant?: `large` | `default` | `medium`;
    size?: `small` | `medium` | `large`;
    padding?: `default` | `none`;
    dialogVisible?: any;
    title?: string;
    subtitle?: string;
    icon?: string;
    iconColor?: EccaColorSet;
    scrollable?: boolean;
    closeIcon?: boolean;
    disabled?: boolean;
  }>(),
  {
    variant: "default",
    subtitle: "",
    iconColor: "primary",
    closeIcon: true,
  },
);

const emit = defineEmits(["update:dialogVisible"]);

const slots = useSlots();

const isDialogVisible = useModelValueWrapper(props, emit, "dialogVisible");

const className = "ui-dialog";
const variantClassName = computed(() => `${className}--${props.variant}`);
</script>

<template>
  <EccaDialog
    v-model="isDialogVisible"
    :class="[`${className} ${variantClassName} ${disabled ? 'disabled' : ''}`]"
    style="z-index: 1900"
    persistent
    :scrollable="props.scrollable"
    :closeIcon="props.closeIcon"
  >
    <VCard
      class="ui-dialog-body"
      :class="{
        'ui-dialog-body--scrollable': props.scrollable,
      }"
      :subtitle="props.subtitle"
    >
      <template v-if="props.title" #title>
        <span v-html="props.title"></span>
      </template>
      <template v-if="icon" #prepend>
        <VIcon :color="iconColor || `primary`">{{ icon }}</VIcon>
      </template>
      <VCardText class="text-left">
        <slot></slot>
      </VCardText>
      <template v-if="slots.actions" #actions>
        <UiActionRow class="py-4">
          <template #center>
            <slot name="actions"></slot>
          </template>
        </UiActionRow>
      </template>
    </VCard>
  </EccaDialog>
</template>

<style lang="scss">
.ecca-dialog.v-dialog {
  &.ui-dialog {
    min-width: 350px;

    &--default {
      width: 550px;
    }

    &--medium {
      width: 700px;
    }

    &--large {
      width: 80%;
      min-width: 500px;

      .ui-dialog-body {
        background: linear-gradient(180deg, #ffffff 0%, #f9f9f9 47.4%);
      }
    }

    .ui-dialog-body {
      &--scrollable {
        &.v-card {
          padding: 0;

          .v-card-title {
            padding-top: 36px;
          }
        }
        .v-card-text {
          padding: 0 80px;
        }
      }
    }

    .ui-dialog-body.v-card {
      .v-card-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .v-card-title,
      .v-card-subtitle {
        text-align: center;
        text-overflow: initial;
        white-space: initial;
      }

      .v-card-title {
        line-height: 2.571rem;
        font-size: 1.714rem;
        margin: 0.286rem 0 0;
      }

      .v-card-subtitle {
        line-height: 1.429rem;
      }
      .v-card-actions {
        justify-content: center;
      }
    }
  }
  &.disabled .ecca-dialog__close {
    opacity: 0.3;
    pointer-events: none;
  }
}
</style>
