const writtenContentRouteBaseName = "written-content";

export const writtenContentRoutesNames = {
  root: "written-content",
  localizations: () => `${writtenContentRoutesNames.root}.localizations`,
  adaptations: () => `${writtenContentRoutesNames.root}.adaptations`,
};

export const writtenContentRoutes = (basePath: string): any => {
  const path = `${basePath}/:productId/${writtenContentRouteBaseName}`;
  return [
    {
      path,
      component: () => import("@/modules/writtenContent/layouts/SubPage.vue"),
      meta: {
        auth: true,
        scope: ["contents:written_content"],
      },

      children: [
        {
          path: `${path}/localizations/:writtenContentLocalizationId?`,
          name: writtenContentRoutesNames.localizations(),
          component: () =>
            import("@/modules/writtenContent/views/WrittenContent.vue"),
          meta: {
            label: "Localization",
            hasSubmenu: false,
          },
        },
        {
          path: `${path}/localizations/:writtenContentLocalizationId/adaptations/:writtenContentAdaptationId?`,
          name: writtenContentRoutesNames.adaptations(),
          component: () =>
            import("@/modules/writtenContent/views/WrittenContent.vue"),
          meta: {
            label: "Adaptation",
            hasSubmenu: false,
          },
        },
      ],
    },
  ];
};
