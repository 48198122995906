<script lang="ts" setup>
import {
  createReusableTemplate,
  useElementVisibility,
  useTimeoutFn,
} from "@vueuse/core";
import { ref, useAttrs, useSlots, watch } from "vue";
import { config } from "@/data/config";
import { useAppStore } from "@/store/app";

const props = defineProps<{
  displayBottomBar?: boolean;
  bottomBarLayout?: `center` | `start` | `end`;
}>();

const systemStore = useAppStore();

const slots = useSlots();
const attrs = useAttrs();

const container = ref<HTMLElement | null>();
const targetIsVisible = useElementVisibility(container);

const { start, stop } = useTimeoutFn(() => {
  if (targetIsVisible.value || !props.displayBottomBar) return;

  systemStore.bottomActionBar.isVisible = true;
  systemStore.bottomActionBar.height = container.value?.offsetHeight ?? 0;
}, 10);

const showBottomBar = () => {
  start();
};
const hideBottomBar = () => {
  stop();
  systemStore.bottomActionBar.isVisible = false;
  systemStore.bottomActionBar.height = 0;
};

watch(
  () => targetIsVisible.value,
  (value) => {
    if (!props.displayBottomBar) return;

    if (value) {
      hideBottomBar();
    } else {
      showBottomBar();
    }
  },
);
const [DefineTemplate, ReuseTemplate] = createReusableTemplate();
</script>
<template>
  <DefineTemplate>
    <div
      v-if="slots.default"
      class="ui-action-row__col ui-action-row__col--start"
    >
      <slot></slot>
    </div>
    <div
      v-if="slots.center"
      class="ui-action-row__col ui-action-row__col--center"
    >
      <slot name="center"></slot>
    </div>
    <div v-if="slots.end" class="ui-action-row__col ui-action-row__col--end">
      <slot name="end"></slot>
    </div>
  </DefineTemplate>

  <div v-bind="attrs" ref="container" class="ui-action-row">
    <ReuseTemplate />
  </div>

  <Teleport
    v-if="props.displayBottomBar && systemStore.bottomActionBar.isVisible"
    to="body"
  >
    <div
      class="ui-action-row ui-action-row--fixed ui-action-row ui-action-row--bottom"
      :class="[
        bottomBarLayout ? `ui-action-row--${bottomBarLayout}` : ``,
        systemStore.bottomActionBar.isVisible ? `d-flex` : `d-none`,
      ]"
      :style="[
        `padding-left: ${
          bottomBarLayout !== 'center' ? config.drawer.width : 0
        }px`,
      ]"
    >
      <slot v-if="slots.bottomBar" name="bottomBar"></slot>
      <ReuseTemplate v-else />
    </div>
  </Teleport>
</template>

<style lang="scss">
.ui-action-row {
  display: flex;
  justify-content: space-between;

  &--bottom {
    display: none;
  }
  &--center {
    justify-content: center;
  }

  &--end {
    justify-content: end;
  }

  &--start {
    justify-content: start;
  }

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
    border-top: 1px solid rgba(var(--v-theme-light-grey), 0.5);
    padding: 1rem;
    display: none;
  }

  &__col {
    gap: 1rem;
    display: flex;

    &--start {
      justify-content: flex-start;
    }
    &--end {
      justify-content: flex-end;
    }
    &--center {
      justify-content: center;
    }
  }
}
</style>
