<script setup lang="ts">
import ConfirmCancelAIGeneration from "@/modules/writtenContent/components/dialogs/ConfirmCancelAIGeneration.vue";
import ProcessingAISnackbar from "@/modules/writtenContent/components/ProcessingAISnackbar.vue";
import { useWrittenContentStore } from "@/modules/writtenContent/store";

const writtenContentStore = useWrittenContentStore();
</script>

<template>
  <div v-for="(item, index) in writtenContentStore.aiTasks" :key="index">
    <ProcessingAISnackbar
      v-if="item"
      :key="'pas' + item.writtenContentId"
      v-model:dialog-visible="item.visible"
      :position="index + 1"
      :sklId="item.skl_id"
      :taskItem="item"
      :productRecommendationPath="item.recommendation_file_path"
      :writtenContentData="item.writtenContentData"
    />
    <ConfirmCancelAIGeneration
      v-if="item"
      :key="'ccag' + item.writtenContentId"
      v-model:dialog-visible="item.cancelVisible"
      :currentWrittenContent="item.writtenContentId"
      @aiWrittenContentCancelProcess="item.cancelAction = true"
    />
  </div>
</template>
